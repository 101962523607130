var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('b-card',[_c('validation-observer',{ref:"specialCommoditiesValidation"},[_c('b-form',[_c('b-row',[(_vm.companyList.length > 1)?_c('b-col',[_c('b-form-group',{attrs:{"label":"Company","label-for":"company"}},[_c('validation-provider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"company","clearable":false,"reduce":function (option) { return option.id; },"options":_vm.companiesList},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1797049423)})],1)],1):_vm._e(),_c('b-col',[_c('b-form-group',{attrs:{"label":"Country code","label-for":"country-code"}},[_c('validation-provider',{attrs:{"name":"Country code","rules":"max:2|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"country-code","name":"country-code","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"HS Code","label-for":"hs-code"}},[_c('validation-provider',{attrs:{"name":"HS Code","rules":"required|max:10|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"hs-code","name":"hs-code","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.hsCode),callback:function ($$v) {_vm.hsCode=$$v},expression:"hsCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Description","label-for":"Description"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","name":"description","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Type","label-for":"type"}},[_c('v-select',{attrs:{"id":"type","options":_vm.typeOptions},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),(_vm.type.value === 'reduced_vat')?_c('b-col',[_c('b-form-group',{attrs:{"label":"Reduced VAT Code","label-for":"reduced-vat-code"}},[_c('validation-provider',{attrs:{"name":"Reduced VAT Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reduced-vat-code","name":"reduced-vat-code","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.additionalData.reduced_vat),callback:function ($$v) {_vm.$set(_vm.additionalData, "reduced_vat", $$v)},expression:"additionalData.reduced_vat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3534195308)})],1)],1):_vm._e(),(_vm.type.value === 'prohibited')?_c('b-col',[_c('b-form-group',{attrs:{"label":"Origin country","label-for":"origin-country"}},[_c('validation-provider',{attrs:{"name":"Origin country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"origin-country","name":"origin-country","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.additionalData.origin_country),callback:function ($$v) {_vm.$set(_vm.additionalData, "origin_country", $$v)},expression:"additionalData.origin_country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1228932215)})],1)],1):_vm._e(),(_vm.type.value === 'replace_hs_code')?_c('b-col',[_c('b-form-group',{attrs:{"label":"Replace HS code to","label-for":"replace-hs-code-to"}},[_c('validation-provider',{attrs:{"name":"Replace HS code to","rules":"required|max:6|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"replace-hs-code-to","name":"replace-hs-code-to","state":errors.length > 0 ? false : null,"autocomplete":"off"},model:{value:(_vm.additionalData.replace_hs_code_to),callback:function ($$v) {_vm.$set(_vm.additionalData, "replace_hs_code_to", $$v)},expression:"additionalData.replace_hs_code_to"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,109334954)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"type":"button","variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }